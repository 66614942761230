import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ArrecadadoFuncionario from "../components/ArrecadadoFuncionario";
import VendidoFuncionario from "../components/VendidoFuncionario";
import ContagemProdutosVendidos from "../components/ContagemProdutosVendidos";
import ArrecadadoCaixas from "../components/ArrecadadoCaixas";

import DevolvidoCaixa from "../components/DevolvidoCaixa";
import DesfeitoCreditos from "../components/DesfeitoCreditos";
import VendasDesfeitasPorFuncionario from "../components/VendasDesfeitasPorFuncionario";
import { getEventDetail } from "../services/caixas";

export default function EventosDetalhes() {
  const [event, setEvent] = useState({ fechamento: { caixas: [] } });
  const [fechamento, setFechamento] = useState({
    arrecadadoPorFuncionario: [],
    vendidoPorFuncionario: [],
    staffCredit: [],
  });
  const [contagemProdutos, setContagemProdutos] = useState([]);
  const params = useParams();
  const [totalCaixas, setTotalCaixas] = useState(0);

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, []);

  function onLoad() {
    getEventDetails(params.id);
  }

  function toggleDisplay(id) {
    let x = document.getElementById(id);
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  async function getEventDetails(id) {
    const res = await getEventDetail(id);
    setEvent(res);
    if (res.fechamento) {
      setFechamento(res.fechamento);
      setContagemProdutos(res.fechamento.totalProdutosVendidos);
      let totalC = 0;
      let totalD = 0;

      res.fechamento.caixas.forEach((c) => {
        totalC = totalC + c.cartao;
        totalD = totalD + c.entrada;
      });
      setTotalCaixas(totalC + totalD);
    }
  }
  return (
    <div>
      {event.inicio ? (
        <div>
          <h1>Deatalhes do Evento {event.name}</h1>
          {new Date(event.inicio).toLocaleDateString("br")}
          <p>
            Entrada / Consuma
            <br />
            R$ {event.valor_entrada} / R$ {event.valor_consuma}
          </p>
          <p>
            <a href={"/eventos/usuarios/" + params.id}>Clientes nesse evento</a>
          </p>
          {fechamento.caixas ? (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-sm">
                    <div className="card">
                      <div className="card-header">
                        Arrecadado{" "}
                        <button
                          className="btn-sm btn-primary mx-2"
                          onClick={() => {
                            toggleDisplay("ArrecadadoFuncionario");
                          }}
                        >
                          +/-
                        </button>
                      </div>
                      <div
                        className="card-body"
                        id="ArrecadadoFuncionario"
                        style={{ display: "none" }}
                      >
                        <ArrecadadoFuncionario
                          fechamento={fechamento}
                          caixas={event.fechamento.caixas}
                        />
                      </div>
                    </div>
                    <div className="card my-4">
                      <div className="card-header">
                        Créditos adicionados para Staff{" "}
                        <button
                          className="btn-sm btn-primary mx-2"
                          onClick={() => {
                            toggleDisplay("staffCredit");
                          }}
                        >
                          +/-
                        </button>
                      </div>
                      <div
                        className="card-body"
                        id="staffCredit"
                        style={{ display: "none" }}
                      >
                        <h5 className="card-title">Total</h5>
                        {fechamento.staffCredit &&
                        fechamento.staffCredit.length ? (
                          <p className="card-text">
                            R${" "}
                            {fechamento.staffCredit.reduce(
                              (acc, obj) => acc + obj.totalCreditos,
                              0
                            )}
                            .00
                          </p>
                        ) : (
                          <p className="card-text">R$ 0.00</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm">
                    <div className="card">
                      <div className="card-header">
                        Total de créditos adicionados{" "}
                        <button
                          className="btn-sm btn-primary mx-2"
                          onClick={() => {
                            toggleDisplay("totalCreditosAdicionados");
                          }}
                        >
                          +/-
                        </button>
                      </div>
                      <div
                        className="card-body"
                        id="totalCreditosAdicionados"
                        style={{ display: "none" }}
                      >
                        <h5 className="card-title">Total</h5>
                        <p className="card-text">
                          R$ {fechamento.arrecadado}.00
                        </p>
                      </div>
                    </div>

                    <div className="card my-2">
                      <div className="card-header">
                        Total arrecadado pelos caixas{" "}
                        <button
                          className="btn-sm btn-primary mx-2"
                          onClick={() => {
                            toggleDisplay("totalArrecadadoPelosCaixas");
                          }}
                        >
                          +/-
                        </button>
                      </div>
                      <div
                        className="card-body"
                        id="totalArrecadadoPelosCaixas"
                        style={{ display: "none" }}
                      >
                        <h5 className="card-title">Total</h5>
                        <p className="card-text">R$ {totalCaixas}.00</p>
                      </div>
                    </div>

                    <div className="card my-2">
                      <div className="card-header">
                        Diferença entre créditos adicionados e caixas{" "}
                        <button
                          className="btn-sm btn-primary mx-2"
                          onClick={() => {
                            toggleDisplay("diferencaCreditosAdicionadosCaixas");
                          }}
                        >
                          +/-
                        </button>
                      </div>
                      <div
                        className="card-body"
                        id="diferencaCreditosAdicionadosCaixas"
                        style={{ display: "none" }}
                      >
                        <h5 className="card-title">Total</h5>
                        <p className="card-text">
                          R$ {totalCaixas - fechamento.arrecadado}.00
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="container">
                  <div className="row">
                    <div className="col-sm">
                      <div className="card my-2">
                        <div className="card-header">
                          Créditos removidos (Por ADM) - Caixa{" "}
                          <button
                            className="btn-sm btn-primary mx-2"
                            onClick={() => {
                              toggleDisplay("DesfeitoCreditos");
                            }}
                          >
                            +/-
                          </button>
                        </div>
                        <div
                          className="card-body"
                          id="DesfeitoCreditos"
                          style={{ display: "none" }}
                        >
                          <DesfeitoCreditos fechamento={event.fechamento} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm">
                      <div className="card">
                        <div className="card-header">
                          Créditos devolvidos (Por ADM) - Bar{" "}
                          <button
                            className="btn-sm btn-primary mx-2"
                            onClick={() => {
                              toggleDisplay("creditosDevolvidos");
                            }}
                          >
                            +/-
                          </button>
                        </div>
                        <div
                          className="card-body"
                          id="creditosDevolvidos"
                          style={{ display: "none" }}
                        >
                          <DevolvidoCaixa fechamento={event.fechamento} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="card">
                  <div className="card-header">
                    Arrecadado pelos Caixa{" "}
                    <button
                      className="btn-sm btn-primary mx-2"
                      onClick={() => {
                        toggleDisplay("caixas");
                      }}
                    >
                      +/-
                    </button>
                  </div>
                  <div
                    className="card-body"
                    id="caixas"
                    style={{ display: "none" }}
                  >
                    <ArrecadadoCaixas caixas={event.fechamento.caixas} />
                  </div>
                </div>
              </div>
              <hr />
              <div className="container">
                <div className="row">
                  <div className="col-sm">
                    <div className="card">
                      <div className="card-header">
                        Vendido pelo Bar{" "}
                        <button
                          className="btn-sm btn-primary mx-2"
                          onClick={() => {
                            toggleDisplay("vendidoFuncionario");
                          }}
                        >
                          +/-
                        </button>
                      </div>
                      <div
                        className="card-body"
                        id="vendidoFuncionario"
                        style={{ display: "none" }}
                      >
                        <VendidoFuncionario fechamento={fechamento} />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm">
                    <div className="card">
                      <div className="card-header">
                        Vendido pelo Bar{" "}
                        <button
                          className="btn-sm btn-primary mx-2"
                          onClick={() => {
                            toggleDisplay("vendidosBar");
                          }}
                        >
                          +/-
                        </button>
                      </div>
                      <div
                        className="card-body"
                        id="vendidosBar"
                        style={{ display: "none" }}
                      >
                        <h5 className="card-title">Total</h5>
                        <p className="card-text">R$ {fechamento.vendido}.00</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <h4>
                Vendas desfeitas
                <button
                  className="btn-sm btn-primary mx-2"
                  onClick={() => {
                    toggleDisplay("devolvidosBar");
                  }}
                >
                  +/-
                </button>
              </h4>
              <div id="devolvidosBar" style={{ display: "none" }}>
                <VendasDesfeitasPorFuncionario fechamento={fechamento} />
              </div>
              <hr />
              <h4>
                Contagem de Produtos Vendidos pelo Bar{" "}
                <button
                  className="btn-sm btn-primary mx-2"
                  onClick={() => {
                    toggleDisplay("arrecadadoBar");
                  }}
                >
                  +/-
                </button>
              </h4>
              <div id="arrecadadoBar" style={{ display: "none" }}>
                <ContagemProdutosVendidos contagemProdutos={contagemProdutos} />
              </div>
            </>
          ) : (
            <>Evento ainda não fechado pelo sistema. </>
          )}
        </div>
      ) : (
        <>Carregando...</>
      )}
    </div>
  );
}
