import React from 'react'

export default function VendidoFuncionario({fechamento}) {
  return (
    <table className="table table-hover">
                <thead>
                <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Total</th>
                </tr>
                </thead>
                <tbody>
                { fechamento.vendidoPorFuncionario.map((u) => (
                    <tr key={u._id} >
                      <td>{u.admin_fullname}</td> 
                      <td>R$ {u.total}.00</td>
                    </tr>
                ))}
                </tbody>
                </table>
  )
}
