import React from 'react'

export default function VendasDesfeitasPorFuncionario({fechamento}) {

    function formatDate(date){
        return new Date(date).toLocaleTimeString("pt-BR")
    }

    function getName(user) {
        if (user.fullname) {
            return user.fullname.split(" ")[0] 
        } else {
            return user.tel
        }
    }

  return (
    <table className="table table-striped text-center">
        <thead>
            <tr>
                <th scope="col">Bar</th>
                <th scope="col">Produto</th>
                <th scope="col">#</th>
                <th scope="col">Valor</th>
                <th scope="col">Bar Quando</th>
                <th scope="col">ADM</th>
                <th scope="col">ADM Quando</th>
                <th scope="col">Comanda</th>
                <th scope="col">Cliente</th>
            </tr>
            </thead>
            <tbody>
            { fechamento.vendasDefeitasPorFuncionario ? (
                fechamento.vendasDefeitasPorFuncionario.map((u) => (
                <tr key={u._id} >
                <td>{u.usage.admin_fullname.split(" ")[0]}</td> 
                <td>{u.usage.product}</td>
                <td>{u.usage.quantity}</td>
                <td>R$ {u.usage.value}.00</td>
                <td>{ formatDate(u.usage.created_at)}</td>
                <td>{u.admin_fullname.split(" ")[0]}</td>
                <td>{ formatDate(u.created_at) }</td>
                <td>{u.usage.comanda}</td>
                <td>{ getName(u)}</td>
                </tr>
            ))
            ): (
                <></>
            )}
        </tbody>
    </table>
  )
}
