import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import api from "../services/api"
import ListaContagemProdutosVendidos from '../components/ListaContagemProdutosVendidos';
import ProdutosEmEstoque from '../components/ProdutosEmEstoque';
import LoaderButton from "../components/LoaderButton"

export default function Entrada() {

    const [inicio, setInicio] = useState('')
    const [fim, setFim] = useState('')
    const [contagem, setContagem] = useState([])
    const [arrecadado, setArrecadado] = useState(0)
    const [produtos, setProdutos] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    async function handleSubmit(e){
        setIsLoading(true)
        e.preventDefault()
        const i = new Date(inicio).toISOString()
        const f = new Date(fim).toISOString()
        
        try {
            setIsLoading(true)
            const getC = await api.get(`users/r/contagemVendidos/${i}/${f}`)
        
            var total = 0
            getC.data.data.forEach((r) => {
                total += r.total
            })
            setArrecadado(total)
            var x = document.getElementById("resultado-pesquisa");
            x.style.display = "block";

            const p = await api.get('bar')
            setProdutos(p.data)
            var mergeContagem = [];
            for (const c of getC.data.data) {
                let unitario = p.data.find(e => e.title === c._id)
                if (unitario){
                    mergeContagem.push({
                        ...c,
                        totalArrecadado: c.total * unitario.price
                    })
                }else{
                    mergeContagem.push({
                        ...c,
                        totalArrecadado: c.total * 1
                    })
                }
                
            }

            setContagem(mergeContagem)           
        }
        catch(e){
            alert(e)
        }
        setIsLoading(false)
    }
    

    return (
        <div>
            <h1>Estoque</h1>
            <hr />
            <h3>Período</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-group row">
                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Início</label>
                        <div className="col-sm-10">
                        <DatePicker
                            selected={inicio}
                            onChange={(date) => setInicio(date)}
                            timeInputLabel="Horário:"
                            dateFormat="dd/MM/yyyy h:mm aa"
                            className="form-control"
                            showTimeInput
                        />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Fim</label>
                        <div className="col-sm-10">
                        <DatePicker
                            selected={fim}
                            onChange={(date) => setFim(date)}
                            timeInputLabel="Horário:"
                            dateFormat="dd/MM/yyyy h:mm aa"
                            className="form-control"
                            showTimeInput
                        />
                        </div>
                    </div>
                    <LoaderButton
                    isLoading={isLoading}
                    onClick={handleSubmit}
                    className="btn btn-success">Pesquisar</LoaderButton>
                </form>
                <hr />
                <div id="resultado-pesquisa" style={{display: "none"}}>
                    <h2>De {new Date(inicio).toLocaleString("pt-BR")} até {new Date(fim).toLocaleString("pt-BR")} </h2> 
                    <h3>Foram vendidos {arrecadado} items</h3>
                    <div id="graficos users">
                        <ListaContagemProdutosVendidos itemsPerPage={10} items={contagem}  />
                    </div>
                </div>
                <hr />
                <h2>Produtos em Estoque</h2>
                <ProdutosEmEstoque itemsPerPage={25} items={produtos} />
        </div>
    )
}
