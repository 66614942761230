import React from 'react'

export default function ArrecadadoFuncionario({fechamento, caixas}) {  
  function compare( a, b ) {
    if ( a.admin_fullname < b.admin_fullname ){
      return -1;
    }
    if ( a.admin_fullname > b.admin_fullname ){
      return 1;
    }
    return 0;
  }

  function getCaixa(admin) {
      const user = fechamento.arrecadadoPorFuncionario.find((e) => e.admin_fullname === admin)
      if (user){
        return user
      } else {
        return []
      }    
  }

  return (
    <table className="table table-hover">
                <thead>
                <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Crédito</th>
                    <th scope="col">Caixa</th>
                    <th scope="col">Diferença</th>
                </tr>
                </thead>
                <tbody>
                { caixas.sort(compare).map((u) => (
                    <tr key={u.adminFullName} >
                      <td>{u.adminFullName.split(" ")[0]}</td> 
                      <td>R$ {  getCaixa(u.adminFullName).total  }.00</td>
                      <td>R$ {  u.entrada + u.cartao  }.00</td>
                      <td>R$ {  (u.entrada + u.cartao ) - getCaixa(u.adminFullName).total   }.00</td>
                    </tr>
                ))}
                </tbody>
            </table>
  )
}
