import React, { useState, useEffect } from "react";

import api from "../services/api";
import { dinheiro } from "../helpers/Formata";
export default function EventosArrecadados() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, []);

  function onLoad() {
    getEvents();
  }

  async function getEvents() {
    const data = await api.get("/events");
    setEvents(data.data);
  }

  function parseNumber(id) {
    if (id) {
      return `R$ ${dinheiro(id)}`;
    } else {
      return "?";
    }
  }

  return (
    <div>
      <div>
        <h1>Eventos</h1>
        {events.length > 0 ? (
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Evento</th>
                <th scope="col">Data</th>
                <th scope="col">Caixa</th>
                <th scope="col">Bar</th>
                <th scope="col">Pessoas</th>
                <th scope="col">T/Médio</th>
              </tr>
            </thead>
            <tbody>
              {events.map((e) => (
                <tr key={e.id}>
                  <th scope="row">
                    <a href={"/eventos/detalhe/" + e.id}>{e.name}</a>
                  </th>
                  <td>{new Date(e.inicio).toLocaleDateString("br")}</td>
                  <td>{parseNumber(e.arrecadado)}</td>
                  <td>{parseNumber(e.vendido)}</td>
                  <td>
                    <a href={"/eventos/usuarios/" + e.id}>({e.pessoas})</a>
                  </td>
                  <td>
                    {" "}
                    {e.pessoas > 0 ? parseNumber(e.arrecadado / e.pessoas) : 0}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <>Carregando...</>
        )}
      </div>
    </div>
  );
}
