import React, { useState } from 'react'
import ToogleDiv from '../components/ToogleDiv'
import LoaderButton from '../components/LoaderButton'
import api from '../services/api'
import { PaginateUsers } from '../components/PaginateUsers'
import { dinheiro, comparaUsoCredito } from '../helpers/Formata'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ListaCreditosParaExpirar from '../components/ListaCreditosParaExpirar'



export default function Relatorios() {
    const [isLoading, setIsLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [totalCredito, setTotalCredito] = useState(0)
    const [creditosInicio, setCreditosInicio] = useState('')
    const [creditosAExpirar, setcreditosAExpirar] = useState([])

    async function getUserWithCredits(){
        try {
            setIsLoading(true)
            const res = await api.get("users/r/creditoValido")
            setUsers(res.data)
            var total = 0
            res.data.forEach((e) => {
                total += e.credit
            })
            console.log(total)
            setTotalCredito(total)
            setIsLoading(false)
        }catch(e){
            setIsLoading(false)
            alert(e)
        }

    }

    async function getCreditosExpirados(){
        try{
        const gCCE = await api.get("users/r/creditosExpirados/" + new Date(creditosInicio).toISOString())
        const gCUE = await api.get("users/r/usoExpirados/" + new Date(creditosInicio).toISOString())

        var entradaSaida = []
        var i = 0
        gCCE.data.forEach((e) => {
            i = gCUE.data.find(u => u._id === e._id)
            if(i) {
                if(e.credito !== i.uso){
                    entradaSaida.push({
                        ...e,
                        uso: gCUE.data.find(u => u._id === e._id).uso
                    })
                }
            }else{
                entradaSaida.push({
                    ...e,
                    uso: 0
                })
            }
        })
        console.log(entradaSaida)
        var limpaEntradaSaida = []
        entradaSaida.forEach((e) => {
            if(e.credito > 0 && e.uso > 0 ){
                limpaEntradaSaida.push(e)
            }
        })
        limpaEntradaSaida.sort(comparaUsoCredito)
        setcreditosAExpirar(limpaEntradaSaida)


        } catch(e) {
            console.log(e.message)
        }

    }
    return (
        <div>
            <h1>Relatórios</h1>
            <div className="alert alert-primary">
                <h2>Usuários com créditos <ToogleDiv id='user-creditos' /> </h2>
                <div className="users" id="user-creditos" style={{display: "none"}}> 
                    <LoaderButton 
                        isLoading={isLoading}
                        onClick={getUserWithCredits}
                    >
                        Carregar
                    </LoaderButton>
                    <h3>Crédito Acumulado: R$ <b>{dinheiro(totalCredito)}</b> </h3>
                    <hr />
                    <PaginateUsers itemsPerPage={50} items={users}/>
                </div>
            </div>

            <div className="alert alert-warning">
                <h2>Expiração de Créditos <ToogleDiv id='expiracao-creditos' /> </h2>
                <div id="expiracao-creditos">
                {/* <div id="expiracao-creditos" style={{display: "none"}}> */}
                    <div>
                    <DatePicker
                            selected={creditosInicio}
                            onChange={(date) => setCreditosInicio(date)}
                            timeInputLabel="Horário:"
                            dateFormat="dd/MM/yyyy h:mm aa"
                            className="form-control"
                            showTimeInput
                        />
                    <LoaderButton 
                            isLoading={isLoading}
                            onClick={getCreditosExpirados}
                        >
                        Carregar
                    </LoaderButton>
                    </div>
                    <hr />
                    <ListaCreditosParaExpirar itemsPerPage={50} items={creditosAExpirar} />
                </div>
            </div>
        </div>
    )
}
