import React, { useState } from 'react'

import api from "../services/api"

import {telefone} from '../helpers/Formata'

export default function PesquisaUsuario() {


    const [userSearch, setUserSearch] = useState([])
    const [searchUser, setSearchUser] = useState("")

    function toggleDisplay(id){
        var x = document.getElementById(id);
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
      }
      async function handleSubmit(event){
        event.preventDefault()
        const user = await api.get(`users/get/${searchUser}`)
        setUserSearch(user.data)
    }

    return (
        <div>
            <div id="usuario_pesquisa" >
                <legend>Pesquisar por Usuário <button className='btn-sm btn-success' onClick={ () => { toggleDisplay("usersearch") }} >+/-</button></legend>
                <form onSubmit={handleSubmit} id="usersearch" className="form-horizontal" style={{display: "none"}}>
                
                <div className="form-group">
                <label className="col-md-4 control-label" htmlFor="textinput">Nome/Telefone/CPF</label>  
                <div className="col-md-4">
                <input id="textinput" onChange={(e) => setSearchUser(e.target.value)} name="textinput" type="text" placeholder="1999999999" className="form-control input-md" /> 
                <button className='btn btn-success my-2'>Procurar</button>
                </div>
                </div>
                </form>

                
                <div className='usuario_busca_alvo'>

                    { userSearch.map( (u) => (
                    <div key={u.id} className=" m-4 card" style={{width: "20rem",float: 'left'}}>
                    <div className="card-body">
                        <h5 className="card-title">{u.name} {u.lastname}</h5>
                        <p className="card-text">Telefone: {telefone(u.tel)} </p>
                        <p className="card-text">Desde de { new Date(u.created).toLocaleDateString("pt-BR")}</p>                        
                        <a href={"usuario/" + u.id} className="btn btn-primary">Ver tudo</a>
                    </div>
                    </div>
                    ))}
                </div>
                <hr />
            </div>
        </div>
    )
}
