import React, { useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'

import api from '../services/api';


export default function Funcionario() {
    const params = useParams()

    const [nome, setNome] = useState("");
    var [email, setEmail] = useState("");
    const [rg, setRg] = useState("");
    const [cpf, setCpf] = useState("");
    const [birth, setBirth] = useState("");
    const [tel, setTel] = useState("");
    const [role, setRole] = useState("");
    const [feed, setFeed] = useState([]);

    useEffect(() => {
        onLoad()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function onLoad(){
        const user = await api.get("users/" + params.id)
        if(user.data.name) {
            console.log(user)
            setNome(user.data.name + " " + user.data.lastname);
            setEmail(user.data.email);
            if (user.data.rg) {
                setRg(user.data.rg);
            }
            if(user.data.cpf) {
                setCpf(user.data.cpf);
            }
            if(user.data.birth){
                setBirth(new Date(user.data.birth).toISOString().split('T')[0])
            }
            if(user.data.tel){
                setTel(user.data.tel)
            }
            setRole(user.data.role)
        }

        const resFeed = await api.get("/feed/user/" + user.data.id)
        setFeed(resFeed.data)
    }
    
    return (
        <div className = "container" >
        <h1> { nome } </h1> 
    
        <p>Email: { email }</p>


        <p>RG: {rg}</p>
        <p>CPF: {cpf}</p>
        <p>Data de Nascimento: {birth}</p>
        <p>Telefone: {tel}</p>
        <p>Role: {role}</p>
        

        <hr />
        <h2>Atividades desse Usuário</h2>
        <table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Quando</th>
                    <th scope="col">Area</th>
                    <th scope="col">Mensagem</th>
                </tr>
            </thead>
            <tbody>
                {feed &&
                feed.map((p) => (
                    <tr key={p._id}>
                        <th scope="row">{(new Date(p.created).toLocaleString("br"))}</th>
                        <td>{(p.area)}</td>
                        <td>{(p.msg)}</td>
                        </tr>
                ))}
            </tbody>
        </table>
        </div> 
    )

}
