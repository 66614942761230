import React, { useState } from "react";
import { saveAs } from "file-saver";
import { Parser } from "json2csv";

export default function BirthdayList({ users }) {
  const [filter, setFilter] = useState("");
  const [isExported, setIsExported] = useState(false);

  function downloadCSV() {
    const fields = ["userName", "userTel", "userBirth", "userEmail"];
    const json2csvParser = new Parser({ fields });
    const csv = json2csvParser.parse(
      users.map((user) => ({
        userName: `${user.name} ${user.lastname}`,
        userTel: user.tel,
        userBirth: new Date(user.birth).toLocaleDateString("pt-BR"),
        userEmail: user.email,
      }))
    );

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `birth_event_users.csv`);
  }

  return (
    <div className="container mt-5">
      <h3>Selecione o modo de exibiçao</h3>
      <div className="my-5">
        <button
          onClick={() => setIsExported(false)}
          className="btn btn-primary mr-2"
        >
          Legível
        </button>{" "}
        |
        <button
          onClick={() => setIsExported(true)}
          className="btn btn-secondary"
        >
          CSV
        </button>{" "}
        |
        <button onClick={downloadCSV} className="btn btn-warning">
          Baixar arquivo CSV
        </button>
      </div>
      <input
        type="text"
        className="form-control mb-3"
        placeholder="Filtrar usuários"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      />

      <ul className="list-group">
        {users
          .filter((user) =>
            user.name.toLowerCase().includes(filter.toLowerCase())
          )
          .map((user) => (
            <div>
              {isExported ? (
                <>
                  {user.name} {user.lastname},{user.tel},{user.email},
                  {new Date(user.birth).toLocaleDateString("pt-BR")},
                  {user.events.map((ev) => (
                    <>{ev.name},</>
                  ))}
                </>
              ) : (
                <>
                  <li key={user._id} className="list-group-item">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h5 className="mb-0">
                          {user.name} {user.lastname && user.lastname}
                        </h5>
                        <p className="mb-0">{user.email}</p>
                      </div>
                      <span>
                        {user.tel} |{" "}
                        {new Date(user.birth).toLocaleDateString("pt-BR")}
                      </span>
                    </div>
                  </li>
                </>
              )}
            </div>
          ))}
      </ul>
    </div>
  );
}
