import React from 'react'

export default function ToogleDiv(props) {
    function toggleDisplay(id){
        var x = document.getElementById(id);
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    return (
        <button className='btn-sm btn-success' onClick={ () => { toggleDisplay(props.id) }} >+/-</button>
    )
}
