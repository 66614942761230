import React, { useState, useEffect } from "react";

import { AppContext } from "./lib/contextLib";
import Routes from "./Routes";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav"
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { onError } from "./lib/errorLib";
import { getToken, logout, timeLoggedInHours, deleteToken } from "./services/auth"


import "./App.css";


function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const history = useHistory();


  useEffect(() => {
    onLoad();
  }, []);

  function leva(url){
    document.location = url
  }


  async function onLoad() {
    try {
      if (getToken()) {
        userHasAuthenticated(true);

        if (timeLoggedInHours() > 24){
          deleteToken()
          userHasAuthenticated(false);
          alert("Sessão expirada")
          window.location = "/login"
          
        }
      }else{
        userHasAuthenticated(false);
      }
    }
    catch(e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  function handleLogout() {
    userHasAuthenticated(false);
    logout()
    history.push("/")
  }

  return (
  !isAuthenticating && (
    <div className="App container py-3">
      <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
        <LinkContainer onClick={()=> leva("/")} to="/">
          <Navbar.Brand className="font-weight-bold text-muted">
            Gestão RagazziD
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav activeKey={window.location.pathname}>
          <LinkContainer onClick={()=> leva("entrada")} to="/entrada">
            <Nav.Link>Entrada</Nav.Link>
          </LinkContainer>
          <LinkContainer onClick={()=> leva("saida")} to="/saida">
            <Nav.Link>Saída</Nav.Link>
          </LinkContainer>
          <LinkContainer onClick={()=> leva("entrada-saida")} to="/entrada-saida">
            <Nav.Link>Entrada/Saída</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/funcionarios">
            <Nav.Link>Funcionários</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/logout/" onClick={handleLogout}>
            <Nav.Link>Sair</Nav.Link>
          </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
        <Routes />
      </AppContext.Provider>
    </div>
  )
);

}

export default App;
