import React from "react";

export default function ArrecadadoCaixas({ caixas }) {
  let totalVI = 0;
  let totalCartao = 0;
  let totalDinheiro = 0;
  let totalSangria = 0;
  let totalInformado = 0;
  let totalCartaoInformado = 0;
  let totalDiferenca = 0;
  return (
    <>
      <h3>Dinheiro e Sangria</h3>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Número</th>
            <th scope="col">Nome</th>
            <th scope="col">Valor Inicial</th>
            <th scope="col">Dinheiro Arrecadado</th>
            <th scope="col">Dinheiro Informado</th>
            <th scope="col">Sangria</th>
            <th scope="col">Diferença</th>
          </tr>
        </thead>
        <tbody>
          {caixas.map((u) => {
            totalVI = totalVI + u.valorInicial;
            totalDinheiro = totalDinheiro + u.entrada;
            totalSangria = totalSangria + u.saida;
            totalInformado = totalVI + totalDinheiro;
            totalDiferenca =
              u.fechamento -
              totalDiferenca +
              (u.entrada + u.valorInicial - u.saida);

            return (
              <tr key={u._id + "-d"}>
                <td>{u.numero}</td>
                <td>{u.adminFullName.split(" ")[0]}</td>
                <td>R$ {u.valorInicial}.00</td>
                <td>R$ {u.entrada}.00</td>
                <td>R$ {u.fechamento}.00</td>
                <td>R$ {u.saida}.00</td>
                <td>R$ {u.entrada + u.valorInicial - u.saida - u.fechamento }.00</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <h5>Somas</h5>

      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Valor Inicial</th>
            <th scope="col">Arrecadado</th>
            <th scope="col">Informado (Fechamento)</th>
            <th scope="col">Sangria</th>
            <th scope="col">Disponível</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>R$ {totalVI}.00</td>
            <td>R$ {totalDinheiro}.00</td>
            <td>R$ {totalInformado}.00</td>
            <td>R$ {totalSangria}.00</td>
            <td>R$ {totalInformado - totalSangria}.00</td>
          </tr>
        </tbody>
      </table>

      <h3>Cartão</h3>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Número</th>
            <th scope="col">Nome</th>
            <th scope="col">Cartão arrecadado</th>
            <th scope="col">Cartao Informado</th>
            <th scope="col">Diferença</th>
          </tr>
        </thead>
        <tbody>
          {caixas.map((u) => {
            totalCartao = totalCartao + u.cartao;
            if (u.fechamentoCartao) {
              totalCartaoInformado = totalCartaoInformado + u.fechamentoCartao;
            }

            return (
              <tr key={u._id + "-d"}>
                <td>{u.numero}</td>
                <td>{u.adminFullName.split(" ")[0]}</td>
                <td>R$ {u.cartao}.00</td>
                <td>R$ {u.fechamentoCartao}.00</td>
                <td>R$ {u.fechamentoCartao - u.cartao}.00</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <h5>Somas</h5>

      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Cartão arrecadado</th>
            <th scope="col">Cartao Informado</th>
            <th scope="col">Diferença</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>R$ {totalCartao}.00</td>
            <td>R$ {totalCartaoInformado}.00</td>
            <td>R$ {totalCartaoInformado - totalCartao}.00</td>
          </tr>
        </tbody>
      </table>

      <h3>Fechamento</h3>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Número</th>
            <th scope="col">Nome</th>
            <th scope="col">Faturamento</th>
            <th scope="col">Fat Informado</th>
            <th scope="col">Diferença</th>
          </tr>
        </thead>
        <tbody>
          {caixas.map((u) => {
            return (
              <tr key={u._id + "-d"}>
                <td>{u.numero}</td>
                <td>{u.adminFullName.split(" ")[0]}</td>
                <td>R$ {u.cartao + u.entrada + u.saida}.00</td>
                <td>
                  R${" "}
                  {u.fechamentoCartao + u.fechamento + u.saida - u.valorInicial}
                  .00
                </td>
                <td>
                  R${" "}
                  {u.fechamentoCartao +
                    u.fechamento
                    -u.valorInicial -
                    (u.cartao + u.entrada)}
                  .00
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <h5>Somas</h5>

      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Faturamento</th>
            <th scope="col">Fat Esperado</th>
            <th scope="col">Diferença</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>R$ {totalCartao + totalDinheiro}.00</td>
            <td>R$ {totalCartaoInformado + totalInformado - totalVI} .00</td>
            <td>
              R${" "}
              {totalCartaoInformado +
                totalInformado +
                totalSangria -
                totalVI -
                (totalCartao + totalDinheiro + totalSangria)}
              .00
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
