import React, { useEffect, useState } from 'react'
import { dataBr, dinheiro } from '../helpers/Formata'

import './PaginateUsers.css'

import ReactPaginate from 'react-paginate';

function Items({ currentItems }) {
    return (
      <table className="table table-hover">
      <thead>
          <tr>
          <th scope="col">Produto</th>
            <th scope="col">Quantidade</th>
            <th scope="col">Valor</th>
            <th scope="col">Por</th>
            <th scope="col">Vendido em</th>
          </tr>
      </thead>
      <tbody>
          {currentItems &&
          currentItems.map((p) => (
            <tr key={p.usage_id}>
                <th scope="row">{p.product}</th>
                <td>{p.quantity}</td>
                <td>R$ {dinheiro(p.itemTotal)}</td>
                <td>{p.admin_fullname}</td>
                <td>{dataBr(p.created_at)}</td>
                </tr>
          ))}
      </tbody>
      </table>
    );
  }

export default function ListaProdutosVendidos({ itemsPerPage, items }) {
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, items]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        // console.log(
        //   `User requested page number ${event.selected}, which is offset ${newOffset}`
        // );
        setItemOffset(newOffset);
    };
    return (
        <>
      <Items currentItems={currentItems} />
      <hr />
      <ReactPaginate
        breakLabel="..."
        nextLabel="Próximo >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< Anterior"
        renderOnZeroPageCount={null}
      />
    </>
    )
}
