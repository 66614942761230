import api from "./api";

export async function getBirthByMonth(month) {
  try {
    const res = await api.get("/users/birth/" + month)
    return res.data
  } catch(e) {
    console.log(e)
    return false
  }
  

}