import React, { useEffect, useState } from "react";
import { getBirthByMonth } from "../services/users";
import BirthdayList from "../components/BirthdayList";
function MonthSelect({ selectedMonth, onChange }) {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  return (
    <select
      className="form form-control"
      value={selectedMonth}
      onChange={onChange}
    >
      {months.map((month, index) => (
        <option key={index + 1} value={index + 1}>
          {month}
        </option>
      ))}
    </select>
  );
}
export default function Birth() {
  function getMonthName(monthNumber) {
    const months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    // Check if the month number is within the valid range
    if (monthNumber < 1 || monthNumber > 12) {
      return "Invalid month";
    }

    return months[monthNumber - 1]; // -1 because the array is zero-based
  }

  function handleMonthChange(event) {
    setMonth(parseInt(event.target.value));
  }
  const today = new Date();
  const monthNumber = today.getMonth() + 1;
  const [month, setMonth] = useState(monthNumber);
  const [birthUsers, setBirthUsers] = useState([]);
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getBirthByMonth(month).then((users) => {
      setBirthUsers(users);
      setIsLoading(false);
    });
  }, [month]);

  return (
    <div>
      <h1>Aniversariantes</h1>
      <h3>
        Mes de referência: {getMonthName(month)} - {month}
      </h3>
      <div>Mudar para o mes:</div>
      <div>
        <MonthSelect selectedMonth={month} onChange={handleMonthChange} />
      </div>

      {loading ? (
        <>Loading</>
      ) : (
        <div className="text-center">
          {birthUsers.length > 0 ? (
            <div className="my-4">
              <h2>Lista de Aniversariantes  ({birthUsers.length})</h2>
              <BirthdayList users={birthUsers} />
            </div>
          ) : (
            <h2 className="my-4">Sem Aniversariantes</h2>
          )}
        </div>
      )}
    </div>
  );
}
