import React, { useState } from 'react'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import LoaderButton from "../components/LoaderButton"
import api from "../services/api"

import ListaProdutosVendidos from '../components/ListaProdutosVendidos';
import { dinheiro } from '../helpers/Formata';


export default function ComandaPerdas() {
    const [inicio, setInicio] = useState('')
    const [fim, setFim] = useState('')
    const [perdas, setPerdas] = useState([])
    const [perdaTotal, setPerdaTotal] = useState(0)

    const [isLoading, setIsLoading] = useState(false)

    async function getData() {
        setIsLoading(true)
        if (inicio !== "" && fim !== ""){    
            try{
                const user = await api.get(`users/r/perdas/${new Date(inicio).toISOString()}/${new Date(fim).toISOString()}`)
                if(user.data.length){
                    var usage = []
                    var total = 0
                    for (const u of user.data){
                        usage.push(u)
                        total = total + u.itemTotal
                    }
                    setPerdaTotal(total);
                    setPerdas(usage)
                }else{
                    alert("N achei nada")
                }
                
            }catch(e){
                console.log(e)
            }
            
        }
        
        setIsLoading(false)
    }
    return (
        <div>
            <h1>Perdas</h1>
            <div>
            <form>
                    <div className="form-group row">
                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Início</label>
                        <div className="col-sm-10">
                        <DatePicker
                            selected={inicio}
                            onChange={(date) => setInicio(date)}
                            timeInputLabel="Horário:"
                            dateFormat="dd/MM/yyyy h:mm aa"
                            className="form-control"
                            showTimeInput
                        />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Fim</label>
                        <div className="col-sm-10">
                        <DatePicker
                            selected={fim}
                            onChange={(date) => setFim(date)}
                            timeInputLabel="Horário:"
                            dateFormat="dd/MM/yyyy h:mm aa"
                            className="form-control"
                            showTimeInput
                        />
                        </div>
                    </div>
                    <LoaderButton 
                        className="btn btn-success"
                        isLoading={isLoading}
                        onClick={getData} >
                            Pesquisar
                    </LoaderButton>
                    
                </form>
            </div>
            <h2>Resultado</h2>
            <h4>Total: R$ {dinheiro(perdaTotal)}</h4>
            <div>
                <ListaProdutosVendidos itemsPerPage={25} items={perdas} />
            </div>
        </div>
    )
}
