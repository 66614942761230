import api from "./api";

export async function getEventDetail(id) {
  try {
    const res = await api.get("events/" + id);
    return res.data ? res.data : {};
  } catch (e) {
    console.log(e);
  }
}
