import React, { useState } from 'react'
import PesquisaUsuario from '../components/PesquisaUsuario'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries} from 'react-vis';

import "react-vis/dist/style.css";

import api from "../services/api"
import { dinheiro } from '../helpers/Formata';
import ListaRecargasUsuario from '../components/ListaRecargasUsuario';

export default function Entrada() {

    const [inicio, setInicio] = useState('')
    const [fim, setFim] = useState('')
    const [arrecadado, setArrecadado] = useState(0)
    const [recargas, setRecargas] = useState([])
    const [graphData, setGraphData] = useState([])



    function generateGraphData(recargas){
        var graph = []
        recargas.forEach((r) => {            
                graph.push({
                    x: new Date(r.created_at),
                    y: parseInt(r.value)
                })
        })
        
        setGraphData(graph)
    }

    async function handleSubmit(e){
        e.preventDefault()
        const i = new Date(inicio).toISOString()
        const f = new Date(fim).toISOString()
        
        try {
            const p = await api.get(`users/r/recargas/${i}/${f}`)
            const detalhes = await api.get(`users/r/recargasDetalhe/${i}/${f}`)
            generateGraphData(detalhes.data)

            setRecargas(detalhes.data)

            var total = 0
            p.data.data.forEach((r) => {
                total += r.total
            })
            setArrecadado(total)
            var x = document.getElementById("resultado-pesquisa");
            x.style.display = "block";

        }
        catch(e){
            alert(e)
        }
    }
    

    return (
        <div>
            <h1>Entrada</h1>
            <hr />
            <h3>Período</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-group row">
                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Início</label>
                        <div className="col-sm-10">
                        <DatePicker
                            selected={inicio}
                            onChange={(date) => setInicio(date)}
                            timeInputLabel="Horário:"
                            dateFormat="dd/MM/yyyy h:mm aa"
                            className="form-control"
                            showTimeInput
                        />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Fim</label>
                        <div className="col-sm-10">
                        <DatePicker
                            selected={fim}
                            onChange={(date) => setFim(date)}
                            timeInputLabel="Horário:"
                            dateFormat="dd/MM/yyyy h:mm aa"
                            className="form-control"
                            showTimeInput
                        />
                        </div>
                    </div>
                    <button className="btn btn-success">Pesquisar</button>
                </form>
                <hr />
                <div id="resultado-pesquisa" style={{display: "none"}}>
                    <h2>De {new Date(inicio).toLocaleString("pt-BR")} até {new Date(fim).toLocaleString("pt-BR")} </h2> 
                    <h3>R$: {dinheiro(arrecadado)}</h3>
                    <div id="graficos">
                    <XYPlot
                    xType="time" 
                    width={800}
                    height={400}>
                    <HorizontalGridLines />
                    <LineSeries
                        data={graphData}/>
                    <XAxis />
                    <YAxis />
                    </XYPlot>
                    </div>
                </div>
                <hr />
                <ListaRecargasUsuario itemsPerPage={25} items={recargas} />
                <hr />
                <PesquisaUsuario />
        </div>
    )
}
