import React from "react";
import "./NotFound.css";

export default function NotFound() {
  return (
    <div className="NotFound text-center">
      <h3>Mals ai, página errada :/</h3>
    </div>
  );
}
