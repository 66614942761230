import React, { Component } from 'react'
import api from '../services/api';

import {telefone, cpf, dinheiro, dataBr} from '../helpers/Formata'

import "./Usuario.css"


export default class Usuario extends Component {


    constructor(props) {
        super(props)
        this.state = {
            user: [],
            credit_usage: [],
            credit_charge: [],
            comanda_history: []
        }
        this.setUser = this.setUser.bind(this)

    }
    
    async setUser(id){
        const user = await api.get("users/" + id)
        const recargas = await api.get("recargas/user/" + id)
        const consumos = await api.get("consumos/user/" + id)
        console.log(recargas)
        await this.setState({
            user: user.data,        
            credit_usage: consumos.data.reverse(),
            credit_charge: recargas.data.reverse(),
            comanda_history: user.data.comanda_history.reverse(),
        })
    }
    
    componentDidMount() {
        this.onLoad()
    }

    async onLoad(){
        const { id } = this.props.match.params;
        await this.setUser(id)
    }

     toggleDisplay(className) {
        var x = document.getElementById(className);
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
      }
    render() {
        return (
            <div>
                <h1>{ this.state.user.name}  {this.state.user.lastname} </h1>
                <div className='container'>
                    <div className='row my-2'>
                        <div className='col'>
                            Telefone: <b>{ telefone(this.state.user.tel)}</b>
                            </div>
                        <div className='col'>
                            CPF: <b>{ cpf(this.state.user.cpf)}</b>
                        </div>
                        <div className='col'>
                            Email: <b>{this.state.user.email}</b>
                        </div>
                    </div>
                    <div className='row my-2'>
                    <div className='col'>
                        Desde de: <b>{ new Date(this.state.user.created).toLocaleDateString("br")}</b>
                        </div>
                        
                    </div>
                    
                    <h3>Uso de Créditos <button onClick={ () => { this.toggleDisplay("uso-credito") }} className='btn-sm btn-success'>+/-</button></h3> 
                    <div className='row my-2 uso-credito'  >
                        <div className='col' style={{display: ""}} id='uso-credito' >
                            { this.state.credit_usage.map((cu) => (
                                <div key={(cu.created_at) } className=" m-2 card" style={{width: "14rem", height: "17rem",float: 'left'}}>
                                <div className="card-body">
                                    <h5 className="card-title">{cu.title} </h5>
                                    <h6 className="card-subtitle mb-2 text-muted">{ dataBr(cu.created_at)}</h6>
                                    <h4 className="card-text">R$ <b>{dinheiro(cu.value) }</b></h4>
                                    <p className="card-text">{cu.quantity} Unidade(s)<br/>
                                        Comanda: {cu.comanda}
                                    </p>
                                    <p className="card-text">Feito por: {cu.admin_fullname}</p>
                                    
                                </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <h3>Recargas de Créditos <button className='btn-sm btn-success' onClick={ () => { this.toggleDisplay("recarga-credito") }} >+/-</button></h3>
                    <div className='row my-2 recarga-credito' >
                        <div className='col' style={{display: "none"}} id='recarga-credito'>
                            { this.state.credit_charge.map((cc) => (
                                <div key={cc.created_at} className=" m-3 card" style={{width: "14.5rem", height: "13rem",float: 'left'}}>
                                <div className="card-body">
                                    <h5 className="card-title">Recarga de R$ <b>{dinheiro(cc.value)}</b> </h5>
                                    <p>Comanda: {cc.comanda} </p>
                                    <p className="card-text">{ new Date(cc.created_at).toLocaleString("pt-BR")}</p>
                                    <p className="card-text">Feito por<br/> <b>{cc.admin_fullname}</b></p>
                                </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <h3>Comandas <button className='btn-sm btn-success' onClick={ () => { this.toggleDisplay("comandas") }} >+/-</button></h3>
                    <div className='row my-2 recarga-credito' >
                        <div className='col' style={{display: "none"}} id='comandas'>
                            { this.state.comanda_history.map((ch) => (
                                <div key={ch.created_at} className=" m-3 card" style={{width: "14rem", height: "7em",float: 'left'}}>
                                <div className="card-body">
                                    <h5 className="card-title"><b>{ch.changed_to}</b> </h5>
                                    <p className="card-text">{ new Date(ch.created_at).toLocaleString("pt-BR")}</p>
                                </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
