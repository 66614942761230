import React, { useEffect, useState } from 'react';
import api from '../services/api';
import { getId, setClientId, setClientName, setRole, getRole} from "../services/auth"

export default function Selecao() {
    const [user, setUser] = useState({client: []})

    useEffect(() => {
      onLoad()
      async function onLoad(){
        if(getRole() === "Operator") {
            const u = await api.get(`users/${getId()}`)
            setUser(u.data)
        } else {
            window.location = '/'
        }
        
    }
       // eslint-disable-next-line
    }, [])
    
    async function selectClient(clientId){
        const ctz = window.confirm("Tem certeza que é esse?")
        if (ctz){
            try {
                const res = await api.post("users/select/client", {
                    clientId: clientId,
                })
                setClientId(res.data.clientId)
                setClientName(res.data.clientName)
                setRole(res.data.role)
                window.location = '/'
                
            }catch(e){
                
            }
        }
        
    }
    
  return (
    <div>
        
        <h1>Selecione</h1>
        <p></p>

        <div className="text-center">
            { user.client.map((e) => (
                <div key={e.id}>
                    <button id={e.name} className="btn btn-primary" onClick={() => selectClient(e.id)}>{e.name}</button>
                    <hr />
                </div>
            ))}
        </div>
        
    </div>
  )
}
