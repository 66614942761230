import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

import {telefone} from '../helpers/Formata'

import './PaginateUsers.css'

function Items({ currentItems }) {
  return (
    <table className="table table-hover">
    <thead>
        <tr>
        <th scope="col">Nome</th>
        <th scope="col">Telefone</th>
        <th scope="col">Ações</th>
        </tr>
    </thead>
    <tbody>
       
        {currentItems &&
        currentItems.map((u) => (
          <tr key={u.id}>
              <td>{u.name} {u.lastname}</td>
              <td>{ telefone(u.tel)}</td>
              <td><a href={"usuario/" + u.id} >Detalhes</a></td>
          </tr>
        ))}
    </tbody>
    </table>
  );
}

export function PaginateUsers({ itemsPerPage, items }) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      <hr />
      <ReactPaginate
        breakLabel="..."
        nextLabel="Próximo >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< Anterior"
        renderOnZeroPageCount={null}
      />
    </>
  );
}
