import React, { useState } from "react";
import { useAppContext } from "../lib/contextLib";
import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";
import { useHistory } from "react-router-dom";
// import { onError } from "../lib/errorLib";
import api from "../services/api";

import { login, setClientId, setClientName, setRole } from "../services/auth";

import "./Login.css";

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { userHasAuthenticated } = useAppContext();
  const [tel, setTel] = useState("");
  const [password, setPassword] = useState("");

  function validateForm() {
    return tel.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      const res = await api.post("/users/authenticate", { tel: tel.replace(/[^a-zA-Z0-9]/g, ''), password })
      const data = res.data.data
      const rt = res.data.rt

      if (data.client.length === 1) {
        if(data.client.find(c => c.role === "Admin")){
          setClientId(data.client[0].id)
          setClientName(data.client[0].name)
          setRole(data.client[0].role)
          login(
            data.jwtToken, 
            rt, 
            data.name + " " + data.lastname, 
            data.client[0].role, 
            data.id
            );
            userHasAuthenticated(true);
        }else{
          alert("Desculpe, você não pode acessar isso aqui n")
        }
      } else {
        login(
          data.jwtToken, 
          rt, 
          data.name + " " + data.lastname, 
          data.role, 
          data.id
          );
      }

      

      
      history.push("/")
    } catch (e) {
      if (e.message) {
        if(e.message.includes(400)) {
          alert("Usuário ou senha incorretos")
        } else {
          alert(e.message + " " + process.env.REACT_APP_KITNET_BASE_URL)
        }
      }
      
      setIsLoading(false);
    }
  }

  return (
    
    <div className="Login">
      <div className="jumbotron">
      <h1 className="display-4">Sistema de Gestão!</h1>
      <hr className="my-4" />
      <p>Em caso de dúvidas, d@ragazzid.com.br</p>
    </div>


    <Form onSubmit={handleSubmit}>
    <Form.Group size="lg" controlId="email">
    <Form.Label>Telefone </Form.Label> <small> Apenas numeros (19999999999)</small>
    <Form.Control autoFocus type="string"value={tel} onChange={(e) => setTel(e.target.value)}/>
    </Form.Group>

    <Form.Group size="lg" controlId="password">
    <Form.Label>Senha </Form.Label> <small></small>
    <Form.Control autoFocus type="password" onChange={(e) => setPassword(e.target.value)}/>
    </Form.Group>

    <LoaderButton block size="lg" type="submit" isLoading={isLoading} disabled={!validateForm()}
    >
    Login
    </LoaderButton>

    </Form>
    </div>
  );
}
