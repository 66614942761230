import React, {useState, useEffect} from 'react'

import api from '../services/api';
import { telefone } from '../helpers/Formata'
import { Link } from 'react-router-dom';
export default function Funcionarios() {
  const [funcionarios, setFuncionarios] = useState([])

  async function getUsers(){
    const u = await api.get("users/admin")
    setFuncionarios(u.data)
  }

  useEffect(() => {
      onLoad()
    // eslint-disable-next-line
  }, [])
  

  function onLoad(){
    getUsers()
  }

  return (
    <div className="container">
      <div>
      <div>
        <h2>Estatísticas</h2>
          <Link to="eventos/arrecadados">
            <p><button className="btn btn-lg btn-primary">Arrecadado por Evento</button></p>
          </Link>
        <p><button className="btn btn-lg btn-secondary">Arrecadado por Funcionário</button></p>
        <p><button className="btn btn-lg btn-secondary">Geral por Datas</button></p>
      </div>
        <h2>Lista de Usuários e Roles</h2>
        <div>
            </div>
            <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Função</th>
                <th scope="col">Telefone</th>
                <th scope="col">Ação</th>
              </tr>
            </thead>
            <tbody>
            { funcionarios.map ( (u) => (
                    <tr key={u.id} >
                      <td><a href={"f/" + u.id}>{u.name} {u.lastname}</a></td> 
                      <td>{u.role}</td>
                      <td>{telefone(u.tel)}</td>
                      <td><a href={"f/" + u.id}>Editar</a></td>
                    </tr>
            ))}
            </tbody>
          </table>

      </div>
    </div>
  )
}
