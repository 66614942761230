import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dinheiro } from '../helpers/Formata';
import api from '../services/api';

export default function EntradaSaida() {

    const [inicio, setInicio] = useState('')
    const [fim, setFim] = useState('')
    const [entrada, setEntrada] = useState(0)
    const [entradaEspecial, setEntradaEspecial] = useState(0)
    const [saida, setSaida] = useState(0)

    async function handleSubmit(e){
        e.preventDefault()
        const i = new Date(inicio).toISOString()
        const f = new Date(fim).toISOString()

        try{
            
            const e = await api.get(`users/r/recargas/${i}/${f}`)
            const eS = await api.get(`users/r/recargasComandasEspeciais/${i}/${f}`)
            const s = await api.get(`users/r/arrecadadoVendidos/${i}/${f}`)

            var total_entrada = 0
            e.data.data.forEach((r) => {
                total_entrada += r.total
            })
            setEntrada(total_entrada)

            var total_entrada_especial = 0
            eS.data.forEach((e) => {
                total_entrada_especial += e.total
            })
            setEntradaEspecial(total_entrada_especial)

            var total_saida = 0
            s.data.data.forEach((r) => {
                total_saida += r.total
            })
            setSaida(total_saida)

            var x = document.getElementById("resultado-pesquisa");
            x.style.display = "block";
        }catch(e){
            alert(e)
        }
    }
    return (
        <div>
            <h1>Entrada x Saída</h1>
            <h3>Período</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-group row">
                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Início</label>
                        <div className="col-sm-10">
                        <DatePicker
                            selected={inicio}
                            onChange={(date) => setInicio(date)}
                            timeInputLabel="Horário:"
                            dateFormat="dd/MM/yyyy h:mm aa"
                            className="form-control"
                            showTimeInput
                        />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Fim</label>
                        <div className="col-sm-10">
                        <DatePicker
                            selected={fim}
                            onChange={(date) => setFim(date)}
                            timeInputLabel="Horário:"
                            dateFormat="dd/MM/yyyy h:mm aa"
                            className="form-control"
                            showTimeInput
                        />
                        </div>
                    </div>
                    <button className="btn btn-success">Pesquisar</button>
                </form>
                <hr />
                <div id="resultado-pesquisa" style={{display: "none"}}>
                    <h2>De {new Date(inicio).toLocaleString("pt-BR")} até {new Date(fim).toLocaleString("pt-BR")} </h2> 
                    <table className="table table-hover">
                        <thead>
                            <tr>
                            <th scope="col">Entrada</th>
                            <th scope="col">Entrada Staff</th>
                            <th scope="col">Saída</th>
                            <th scope="col">Diferença</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>R$ {dinheiro(entrada)}</td>
                            <td>R$ {dinheiro(entradaEspecial)}</td>
                            <td>R$ {dinheiro(saida)}</td>
                            <td>R$ {dinheiro((entrada ) - saida) }</td>
                            </tr>
                        </tbody>
                        </table>
                </div>
        </div>
    )
}
