import React, { useEffect } from "react";
import "./Home.css";

// import { useHistory } from "react-router";

import { getName, getRole } from "../services/auth";
import { Link } from "react-router-dom";



export default function Home() {
  useEffect(() => {
    onLoad()
  
    function onLoad() {
      if(getRole() === "Operator") {
        window.location = "/selecao"
      }
    }
  }, [])

    function leva(url){
      document.location = url
    }
  
  return (
    <div className="Home">
      <div className="lander">
        <h1>Sistema de Gestão</h1>
        <p className="text-muted">Bem vindo</p>
        <h3>{getName()} - {getRole()}</h3>
      </div>
      <div className="text-center">
      <div className="my-2">
        <button onClick={() => leva("/entrada")} className="btn-lg btn-success">
          Entrada
        </button>
        </div>
        <hr />
        <div className="my-2">
        <button onClick={() => leva("/saida")} className="btn-lg btn-warning">
          Saída
        </button>
        </div>
        <hr />
        <div className="my-2">
        <button onClick={() => leva("/entrada-saida")} className="btn-lg btn-primary">
          Entrada x Saída
        </button>
        </div>
        <hr />
        <div className="my-2">
        <button onClick={() => leva("/relatorios")} className="btn-lg btn-info">
          Relatórios
        </button>
        </div>
        <hr />
        <div className="my-2">
        <button className="btn-lg btn-light" onClick={() => leva("/estoque")}>
          Estoque
        </button>
        </div>
        <hr />
        <div className="my-2">
        <button className="btn-lg btn-success" onClick={() => leva("/eventos/arrecadados")}>
          Eventos
        </button>
        </div>
        <hr />
        <div className="my-2">
          <Link to="comanda-perdas">
        <button className="btn-lg btn-danger">
          Comanda de Perda
        </button>
        </Link>
        </div>
        <hr />
        <div className="my-2">
          <Link to="funcionarios">
            <button className="btn-lg btn-primary">
              Funcionários
            </button>
          </Link>
        </div>
        
        <hr />
        <div className="my-2">
        <Link to="niver">
        <button className="btn-lg btn-success" >
        Aniversariantes
        </button>
        </Link>
        </div>
      </div>
    </div>
  );
}
