const TOKEN_KEY = "pacocabaran"
const TOKEN_RT = "refresh"
const TTL = "TTL"
const NAME = "NAME"
const ROLE = "ROLE"
const ID = "ID"
const CLIENT = "CLIENT_ID"
const CLIENT_NAME = "CLIENT_NAME"
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getRefreshToken = () => localStorage.getItem(TOKEN_RT);
export const getName = () => localStorage.getItem(NAME);
export const getRole = () => {return localStorage.getItem(ROLE); };
export const setRole = (role) => localStorage.setItem(ROLE, role);
export const getId = () => localStorage.getItem(ID);
export const getClientId = () => localStorage.getItem(CLIENT);
export const setClientId = (clientId) => localStorage.setItem(CLIENT, clientId)
export const getClientName = () => localStorage.getItem(CLIENT_NAME);
export const setClientName = (clientId) => localStorage.setItem(CLIENT_NAME, clientId)

export const login = function(token, rt, name, role, id) {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(TOKEN_RT, rt);
    localStorage.setItem(TTL, new Date());
    localStorage.setItem(NAME, name);
    localStorage.setItem(ROLE, role);
    localStorage.setItem(ID, id);
};
export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(TOKEN_RT);
    localStorage.removeItem(TTL);
    localStorage.removeItem(NAME);
    localStorage.removeItem(ROLE);
    localStorage.removeItem(ID);
    localStorage.removeItem(CLIENT);
    localStorage.removeItem(CLIENT_NAME);
};

export const timeLoggedInHours = function() {
    let loggedTime = new Date(localStorage.getItem(TTL)).getTime()
    let now = new Date().getTime()
    let hours = (now - loggedTime) / 1000 / 60 / 60
    return Math.round(hours);
    
    
    
    // return Math.abs(minutes)
};

export const deleteToken = () => {
    localStorage.removeItem(TOKEN_KEY);
};