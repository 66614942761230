import React from 'react'

export default function ContagemProdutosVendidos({contagemProdutos}) {
  return (
    <table className="table table-hover">
        <thead>
            <tr>
                <th scope="col">Produto</th>
                <th scope="col">Quantidade</th>
                <th scope="col">Total</th>
            </tr>
            </thead>
            <tbody>
            { contagemProdutos.map((u) => (
                <tr key={u._id} >
                <td>{u.title}</td> 
                <td>{u.totalVendido}</td>
                <td>R$ {u.total}.00</td>
                </tr>
            ))}
        </tbody>
    </table>
  )
}
