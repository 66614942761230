import React from 'react'

export default function DesfeitoCreditos({fechamento}) {
  return (
    <div>
        { fechamento.creditosDevolvidos ? (
            <table className="table table-hover">
            <thead>
            <tr>
                <th scope="col">Nome</th>
                <th scope="col">Valor</th>
            </tr>
            </thead>
            <tbody>
            { fechamento.creditosDevolvidos.map((d) => (
                <tr key={d.admin_fullname} >
                    <td>{d.admin_fullname}</td> 
                    <td>R$ {d.total * -1}.00</td>
                </tr>
            ))}
            </tbody>
        </table>
        ): (
            <>Não tivemos vendas defeitas nesse evento</>
        )}
    </div>
  )
}
