import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Entrada from "./containers/Entrada";
import EntradaSaida from "./containers/EntradaSaida";
import Home from "./containers/Home";
import Login from "./containers/Login";

import NotFound from "./containers/NotFound";
import Relatorios from "./containers/Relatorios";
import Saida from "./containers/Saida";
import Usuario from "./containers/Usuario";
import Estoque from "./containers/Estoque";
import { isAuthenticated } from "./services/auth";
import ComandaPerdas from "./containers/ComandaPerdas";
import Funcionarios from "./containers/Funcionarios"
import Funcionario from "./containers/Funcionario"
import EventosArrecadados from "./containers/EventosArrecadados"
import Selecao from "./containers/Selecao";
import EventosDetalhes from "./containers/EventosDetalhes";
import EventosUsuarios from "./containers/EventosUsuarios";
import Birth from "./containers/Birth";


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      )
    }
  />
);
const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/login" component={() => <Login />} />
      <PrivateRoute exact path="/" component={() => <Home />} />
      
      <PrivateRoute exact path="/entrada" component={() => <Entrada />} />
      <PrivateRoute exact path="/saida" component={() => <Saida />} />
      <PrivateRoute exact path="/selecao" component={Selecao} />
      <PrivateRoute exact path="/usuario/:id" component={Usuario} />
      <PrivateRoute exact path="/relatorios" component={Relatorios} />
      <PrivateRoute exact path="/entrada-saida" component={EntradaSaida} />
      <PrivateRoute exact path="/estoque" component={Estoque} />
      <PrivateRoute exact path="/comanda-perdas" component={ComandaPerdas} />
      <PrivateRoute exact path="/eventos/arrecadados" component={EventosArrecadados} />
      <PrivateRoute exact path="/eventos/detalhe/:id" component={EventosDetalhes} />
      <PrivateRoute exact path="/eventos/usuarios/:id" component={EventosUsuarios} />
      <PrivateRoute exact path="/funcionarios" component={Funcionarios} />
      <PrivateRoute exact path="/niver" component={Birth} />
      
      <PrivateRoute exact path="/f/:id" component={Funcionario} />
      <Route path="*" component={() => <NotFound />} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
